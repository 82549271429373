.home-application-container {
    /* background:red; */
    padding-top: 15px;
    padding-right: 300px;
    padding-left: 300px;
    width: 100vw !important;
    height: 100vh !important;

}

.thirteen {
    /* background:red; */
    padding-left: 150px !important;
}

@media (max-width: 900px) {
    .home-application-container {

        padding-top: 15px;
        padding-right: 50px;
        padding-left: 50px;
        /* width:100% !important; */

    }

    .grid {
        width: 100% !important;
        display: flex !important;
        flex-direction: column !important;
        justify-content: center !important;
        align-items: center !important;
    }

    .vertical {
        /* width:100%; */
    }

    .thirteen {
        width: 100% !important;
        float: right !important;
        padding-left: 0px !important;
    }

    .two {
        width: 100% !important;
        float: left !important;
        padding-left: 0px !important;
    }
}

.application-start-segment {
    background: lightgrey !important;
    padding: 25px !important;
    margin-bottom: 250px !important
}

.flatpickr-input {
    height: 38px !important;
}